var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.status === 'ready')?_c('div',[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(_vm.isCCoEBaseline() ? _vm.roleShortName : _vm.nameExt))]),_c('small',[_vm._v(" form ")])]),_c('CCardBody',[(_vm.isCCoEBaseline() && !_vm.readOnly)?_c('CAlert',{attrs:{"show":"","color":"info"}},[_vm._v("This is a Permission Set managed by OneCloud team. You cannot change anything !! ")]):_vm._e(),(_vm.readOnly)?_c('CAlert',{attrs:{"show":"","color":"info"}},[_vm._v("You cannot modify this Permission Set !")]):_vm._e(),_c('CInput',{attrs:{"label":"Name","placeholder":"Enter permission set name (maximum 19 chars)","prepend":_vm.isCCoEBaseline() ? '' : _vm.accountId,"disabled":!_vm.creationMode || _vm.readOnly,"is-valid":_vm.nameValidator},model:{value:(_vm.roleShortName),callback:function ($$v) {_vm.roleShortName=$$v},expression:"roleShortName"}}),_c('CInput',{attrs:{"label":"Descritpion","placeholder":"Enter description","disabled":_vm.isCCoEBaseline()},model:{value:(_vm.roleDescription),callback:function ($$v) {_vm.roleDescription=$$v},expression:"roleDescription"}}),_c('CInput',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: 'In seconds, must be < 43200',
            placement: 'left',
          }),expression:"{\n            content: 'In seconds, must be < 43200',\n            placement: 'left',\n          }"}],attrs:{"label":"Session duration","placeholder":"Enter the session duration","is-valid":_vm.sessionValidator,"disabled":_vm.readOnly || _vm.isCCoEBaseline()},model:{value:(_vm.sessionDuration),callback:function ($$v) {_vm.sessionDuration=$$v},expression:"sessionDuration"}}),_c('label',[_vm._v("AWS Managed Policies")]),(!_vm.isCCoEBaseline() && !_vm.readOnly)?_c('AwsManagedPoliciesComponent',{on:{"add":function($event){return _vm.addPolicy($event)}}}):_vm._e(),_c('hr',{staticClass:"d-sm-down-none"}),_c('CDataTable',{attrs:{"hover":true,"striped":true,"border":false,"small":true,"fixed":true,"items":_vm.awsManagedPolicies,"fields":_vm.fields,"items-per-page":10,"dark":false,"header":false,"pagination":"","clickableRows":""},on:{"row-clicked":_vm.managedPoliciesSelect},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(!_vm.isCCoEBaseline() && !_vm.readOnly)?_c('CButton',{attrs:{"color":"danger","square":"","size":"sm"},on:{"click":function($event){return _vm.removePolicy(item)}}},[_vm._v("Remove")]):_vm._e()],1)]}}],null,false,402662881)}),_c('hr',{staticClass:"d-sm-down-none"}),_c('label',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: 'Edit your policy here in JSON format',
            placement: 'right',
          }),expression:"{\n            content: 'Edit your policy here in JSON format',\n            placement: 'right',\n          }"}]},[_vm._v(_vm._s(_vm.policyInline))]),_c('Editor',{attrs:{"editorId":"jsonedit","content":_vm.inlinePolicy,"readonly":_vm.isCCoEBaseline() || _vm.readOnly},on:{"change-content":_vm.updateInlinePolicy,"content-valid":_vm.policyValidator}})],1)],1)],1):(_vm.status === 'loading')?_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":"/img/tenor.gif","width":"50"}})]):_c('div',{attrs:{"align":"center"}},[_c('CButton',{attrs:{"color":"info","square":"","size":"sm"},on:{"click":_vm.loadPermissionSet}},[_vm._v("Click to reload Permission Set")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }