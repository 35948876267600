var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" "),_c('div',{staticClass:"card-header-actions"},[_c('CButton',{attrs:{"size":"sm","color":"secondary"},on:{"click":_vm.refresh}},[_vm._v(" Refresh ")]),(_vm.$isAccountOwner(_vm.accountId) && _vm.createClbk != undefined)?_c('CButton',{attrs:{"size":"sm","color":"info"},on:{"click":_vm.create}},[_vm._v(" Create new Permission Set ")]):_vm._e()],1)]})],2),_c('CCardBody',[(!_vm.loading)?_c('div',[_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.itemsExt,"fields":_vm.fields,"items-per-page-select":"","items-per-page":_vm.small ? 20 : 10,"dark":_vm.dark,"pagination":"","sorter":"","sorterValue":{ column: 'RoleName', asc: true },"tableFilter":"","clickableRows":""},on:{"row-clicked":_vm.clicked},scopedSlots:_vm._u([{key:"RoleName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$isCCoEBaseline(item.RoleName) ? _vm.legacy ? item.RoleName : item.RoleName.split(".")[1] : item.RoleName)+" "),(_vm.editable && _vm.$isCCoEBaseline(item.RoleName))?_c('CBadge',{attrs:{"color":"secondary"}},[_vm._v("OneCloud managed")]):_vm._e()],1)]}},{key:"RoleStatus",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.RoleStatus)}},[_vm._v(_vm._s(item.RoleStatus))])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[((item.RoleStatus === 'PROVISIONNED') & !_vm.legacy)?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? "Hide" : "Show")+" ")]):_vm._e(),(_vm.legacy)?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"}},[_vm._v(" LEGACY ")]):_vm._e()],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[(!_vm.legacy)?_c('CCardBody',[_c('h4',[_vm._v(" "+_vm._s(_vm.$isCCoEBaseline(item.RoleName) ? _vm.legacy ? item.RoleName : item.RoleName.split(".")[1] : item.RoleName)+" "),(
                    _vm.editable &&
                    !_vm.$isCCoEBaseline(item.RoleName) &&
                    _vm.$isAccountOwner(_vm.accountId)
                  )?_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Edit ")]):_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"info"},on:{"click":function($event){_vm.editItem(item, _vm.$isCCoEBaseline(item.RoleName))}}},[_vm._v(" View ")]),(
                    _vm.deletable &&
                    !_vm.$isCCoEBaseline(item.RoleName) &&
                    _vm.$isAccountOwner(_vm.accountId)
                  )?_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"danger","disabled":_vm.$isCCoEBaseline(item.RoleName)},on:{"click":function($event){return _vm.deleteItemWarn(item)}}},[_vm._v(" Delete ")]):_vm._e()],1),_c('p',[_vm._v(" Description: "+_vm._s(item.RoleDescription == undefined ? "N/A" : item.RoleDescription)+" ")]),_c('CTextarea',{attrs:{"placeholder":"PAA@equans.com or PAA@engie.com  separated by a comma","size":"sm","is-valid":_vm.multiUsersValidator},model:{value:(_vm.multiUsersAssign.username),callback:function ($$v) {_vm.$set(_vm.multiUsersAssign, "username", $$v)},expression:"multiUsersAssign.username"}}),(_vm.$isAccountOwner(_vm.accountId))?_c('CButton',{attrs:{"size":"sm","color":"info","disabled":!_vm.multiUsersAssign.isValid},on:{"click":function($event){return _vm.assignItem(item)}}},[_vm._v(" Assign user ")]):_vm._e()],1):_vm._e()],1)]}}],null,false,1506923831)})],1):_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":"/img/tenor.gif","width":"50"}})])]),_c('div',[_c('CModal',{attrs:{"title":"Delete permission set","color":"danger","show":_vm.dangerModal.show},on:{"update:show":function($event){return _vm.$set(_vm.dangerModal, "show", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dangerModal.show = false;
            _vm.dangerModal.confirm = '';}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteItem(_vm.dangerModal.RoleName)}}},[_vm._v("DELETE")])]},proxy:true}])},[_vm._v(" Are you sure you want to delete the permission set "+_vm._s(_vm.dangerModal.RoleName)+" ?"),_c('br'),_vm._v(" This action is not reversible and all assignment will be lost."),_c('br'),_c('br'),_vm._v(" Type 'CONFIRM' in the following input box and click on DELETE button to confirm"),_c('br'),_c('CInput',{staticClass:"mb-0 mx-1",attrs:{"label":"Confirm","placeholder":"","size":"sm","horizontal":""},model:{value:(_vm.dangerModal.confirm),callback:function ($$v) {_vm.$set(_vm.dangerModal, "confirm", $$v)},expression:"dangerModal.confirm"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }