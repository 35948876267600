<template>
  <div>
    <div>
      <CModal
        :title="ssoModal.header"
        :color="ssoModal.color"
        size="xl"
        centered
        :show.sync="ssoModal.show"
      >
        <span v-html="ssoModal.content"> </span>
      </CModal>
    </div>
    <AccountInfoBadge :accountId="accountId" />
    <AccountNoticeAlert />
    <CRow>
      <CCol lg="6">
        <CTableRoleList
          :items="getRoles()"
          striped
          caption="Role list"
          v-on:row-clicked="rowclicked"
          :editClbk="editPermissionSet"
          :createClbk="createPermissionSet"
          :assignClbk="assignUser"
          :refreshClbk="rolesRefresh"
          :deleteClbk="deletePermissionSet"
          :editable="true"
          :accountId="accountId"
          :legacy="false"
        >
        </CTableRoleList>
      </CCol>
      <CCol sm="6">
        <CTableUserList
          :items="getAssignedUsers()"
          striped
          :caption="userTableCaption"
          :unassignFct="unassignUser"
          :accountId="accountId"
          :fields="['GID', 'name', 'action']"
          :loading="isLoading"
        />
      </CCol>
    </CRow>
    <div>
      <CModal
        title="Edit permission set"
        :show.sync="psmodal.show"
        size="xl"
        :closeOnBackdrop="false"
      >
        <CFormPermissionSet
          :accountId="accountId"
          :creationMode.sync="psmodal.creationMode"
          :item.sync="psmodal.item"
          :bus="bus"
          @form-valid="psFormIsValid"
          :readOnly="!$isAccountOwner(accountId)"
          @permissionset-created="rolesRefresh"
        />
        <template #footer>
          <CButton @click="psCancel()" color="secondary">Cancel</CButton>
          <CButton
            @click="psSubmit()"
            color="danger"
            :disabled="!$isAccountOwner(accountId) || !psmodal.isValid"
            >Submit</CButton
          >
        </template>
      </CModal>
    </div>
  </div>
</template>

<script>
import CTableRoleList from "./TableRoleList.vue";
import CTableUserList from "./TableUserList.vue";
import AccountInfoBadge from "./AccountInfoBadge.vue";
import CFormPermissionSet from "./FormPermissionSet.vue";
import AccountNoticeAlert from "./AccountNoticeAlert.vue";
import axios from "axios";
import Vue from "vue";

async function listAccountRoles(accountId, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL + "/accounts/" + accountId + "/roles";
    const response = await axios.get(URL, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("Unable to fetch roles");
  }
}

async function listAssignedUsers(accountId, iamRole, accessToken) {
  var res = [];
  var obj = {};
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL +
      "/accounts/" +
      accountId +
      "/roles/" +
      iamRole +
      "/users";
    const response = await axios.get(URL, config);
    // console.log(response.data.SsoRole.Users);
    if (Array.isArray(response.data.SsoRole.Users)) {
      // old RoleUSers
      res = response.data.SsoRole.Users.map(item => {
        if (item.login) {
          return { GID: item.login, email: "N/A", name: item.Name };
        } else {
          return { GID: item, email: "N/A", name: "-" };
        }
      });
    } else {
      for (let i in response.data.SsoRole.Users) {
        obj = {
          userid: response.data.SsoRole.Users[i][0].userid,
          email: "N/A",
          name: response.data.SsoRole.Users[i][0].Name,
          GID: i
        };
        res.push(obj);
      }
    }
    console.log("res", res);

    return res;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    // silently die
    // return {};
    throw new Error("Unable to fetch users list");
  }
}

async function assignUser(accountId, iamRole, user, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
        "Content-Type": "application/json"
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL +
      "/accounts/" +
      accountId +
      "/roles/" +
      iamRole +
      "/users";
    const payload = {
      user: user
    };
    const response = await axios.post(URL, payload, config);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("Unable to assign user");
  }
}

async function unassignUser(accountId, iamRole, user, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
        "Content-Type": "application/json"
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL +
      "/accounts/" +
      accountId +
      "/roles/" +
      iamRole +
      "/users/" +
      user;
    const response = await axios.delete(URL, config);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("Unable to un-assign user");
  }
}

async function deletePermissionSet(accountId, iamRole, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
        "Content-Type": "application/json"
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL +
      "/accounts/" +
      accountId +
      "/roles/" +
      iamRole;
    const response = await axios.delete(URL, config);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("Unable to delete permission set");
  }
}

export default {
  name: "Tables",
  components: {
    CTableRoleList,
    CTableUserList,
    CFormPermissionSet,
    AccountInfoBadge,
    AccountNoticeAlert
  },
  computed: {
    userTableCaption: function() {
      if (this.currentRole != undefined)
        return "Assigned users (" + this.currentRole.RoleName + ")";
      else return "Assigned users (No role selected)";
    }
  },
  watch: {
    accountId: function(newAccountId) {
      console.log("refresh accountID " + newAccountId);
    }
  },
  data() {
    return {
      ssoModal: "",
      isLoading: false,
      currentRole: undefined,
      assignedUsers: [],
      accountId: this.$route.params.id,
      userinfo: undefined,
      listRole: undefined,
      awsManagedPolicies: [
        { value: "arn:::::::", label: "AdministratorAccess" },
        { value: "arn:::::::", label: "ReadOnlyAccess" }
      ],
      psmodal: {
        show: false,
        creationMode: true,
        isValid: false
      },
      bus: new Vue()
    };
  },
  async created() {
    this.userinfo = await this.$auth.getUser();
    var accessToken = await this.$auth.getAccessToken();
    console.log(accessToken);
    try {
      await this.loadSsoModal(accessToken);
      this.listRole = await listAccountRoles(this.accountId, accessToken);
    } catch (error) {
      this.$store.commit("showErrorModal", ["Error", error.message]);
      this.listRole = {
        Account: {
          SsoRoles: []
        }
      };
    }
  },
  methods: {
    async loadSsoModal(accessToken) {
      try {
        this.Loading = true;
        // var accessToken = await this.$auth.getAccessToken();
        await this.$store.dispatch(
          "portalParametersModule/loadMessagesParameters",
          {
            accessToken: accessToken
          }
        );
        this.Loading = false;
        this.ssoModal = this.$store.getters["portalParametersModule/ssoModal"];
      } catch (e) {
        console.log(e);
      }
    },
    getPermissionSetPrefix() {
      return this.accountId + ".";
    },
    getRoles() {
      if (this.listRole != undefined && "Account" in this.listRole) {
        // var res = this.listRole.Account.SsoRoles.map((item, id) => { return {...item, id}});
        // return res;
        return this.listRole.Account.SsoRoles;
      } else return undefined;
    },
    getAssignedUsers() {
      if (this.assignedUsers == undefined) return [];
      return this.assignedUsers;
    },
    editPermissionSet(item) {
      this.psmodal.creationMode = false;
      this.psmodal.item = item;
      this.psmodal.show = true;
    },
    createPermissionSet() {
      this.psmodal.creationMode = true;
      this.psmodal.item = undefined;
      this.psmodal.show = true;
    },
    async deletePermissionSet(item) {
      console.log("delete -- " + JSON.stringify(item));
      this.$store.commit("set", ["busymodalShow", true]);
      var accessToken = await this.$auth.getAccessToken();
      try {
        await deletePermissionSet(this.accountId, item, accessToken);
        this.rolesRefresh();
      } catch (error) {
        this.$store.commit("showErrorModal", ["Error", error.message]);
      } finally {
        this.$store.commit("set", ["busymodalShow", false]);
      }
    },
    async rowclicked(event) {
      console.log(JSON.stringify(event));
      this.currentRole = event;
      var accessToken = await this.$auth.getAccessToken();
      try {
        this.isLoading = true;
        this.assignedUsers = await listAssignedUsers(
          this.accountId,
          this.currentRole.RoleName,
          accessToken
        );
        this.isLoading = false;
      } catch (error) {
        this.$store.commit("showErrorModal", ["Error", error.message]);
        this.assignedUsers = {};
      }
    },
    async unassignUser(event) {
      var accessToken = await this.$auth.getAccessToken();
      var res = undefined;
      try {
        res = await unassignUser(
          this.accountId,
          this.currentRole.RoleName,
          event.GID,
          accessToken
        );
        let idx = this.assignedUsers.findIndex(x => {
          return x.GID === event.GID;
        });
        if (idx >= 0) {
          this.assignedUsers.splice(idx, 1);
        }
        return res;
      } catch (error) {
        this.$store.commit("showErrorModal", ["Error", error.message]);
        return {
          success: false
        };
      }
    },
    async assignUser(event) {
      var accessToken = await this.$auth.getAccessToken();
      this.$store.commit("set", ["busymodalShow", true]);
      try {
        await assignUser(
          this.accountId,
          event.role.RoleName,
          event.user,
          accessToken
        );
        this.assignedUsers.push({ GID: event.user, email: "N/A" });
      } catch (error) {
        this.$store.commit("showErrorModal", ["Error", error.message]);
      } finally {
        this.$store.commit("set", ["busymodalShow", false]);
      }
      return true;
    },
    async rolesRefresh() {
      var accessToken = await this.$auth.getAccessToken();
      try {
        this.listRole = await listAccountRoles(this.accountId, accessToken);
      } catch (error) {
        this.$store.commit("showErrorModal", ["Error", error.message]);
        this.listRole = {
          Account: {
            SsoRoles: []
          }
        };
      }
    },
    psFormIsValid(val) {
      console.log("form-valid " + val);
      this.psmodal.isValid = val;
    },
    psSubmit() {
      console.log("psSubmit");
      this.bus.$emit("submit", {});
      this.psmodal.show = false;
    },
    psCancel() {
      console.log("psCancel");
      this.bus.$emit("cancel", {});
      this.psmodal.show = false;
    }
  }
};
</script>
